export const cleanAlert = async (dispatch) => {
  return dispatch({
    type: 'CLEAN_ERRORS'
  });
};

export const enableAlertMessage = async (dispatch, message) => {
  return dispatch({
    type: 'REQUEST_ERROR',
    payload: message
  });
};
