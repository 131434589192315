import React, { useState } from 'react';

import { Store } from '../../Infrastructure/Store/Store';

import { submitUnsubscribe } from '../../Infrastructure/Actions/Unsubscribe';

import { ReactComponent as SquareIcon } from '../../Common/Assets/images/squareIcon.svg';
import { ReactComponent as CheckedIcon } from '../../Common/Assets/images/checkSquare.svg';
import { ReactComponent as CheckIcon } from '../../Common/Assets/images/checkIcon.svg';

import './SubmitUnsubscribe.scss';

const renderTituloMensagem = () => (
	<div className='mensagem-wrapper'>
		<div className='text-mensagem'>Desejo não receber mais e-mails automáticos da IB Capital</div>
	</div>
);

const renderMensagemDeConfirmacao = () => (
	<div className='mensagem-wrapper'>
		<div className='text-mensagem'>
			Confirmado, você não receberá mais e-mails automáticos da IB Capital
		</div>
	</div>
);

const renderMensagemCompleta = () => (
	<div className='unsubscribe-wrapper'>
		<div className='unsubscribe-texto'>
			A partir de agora você receberá somente os e-mails pontuais da <b>IB Capital</b>. Os e-mails
			automáticos como lembretes de envio de documentos pendentes não serão mais enviados.
		</div>
	</div>
);

const renderMensagem = () => (
	<div className='unsubscribe-texto'>
		Nos processos da <b>IB Capital</b>, havendo documentos pendentes para envio, disparamos e-mails
		automaticamente para relembrá-lo e agilizarmos o cadastro. Caso não deseje mais receber os
		e-mails automáticos, basta informar através da opção abaixo.
	</div>
);

const renderLinkIBCapital = () => (
	<div className='ibcapital-link-wrapper'>
		<div className='ibcapital-link-texto'>
			<a href='http://www.ibcapital.com.br' className='a-link'>
				Acessar site da IB Capital (www.ibcapital.com.br)
			</a>
		</div>
	</div>
);

function SubmitUnsubscribe({ tipoProcesso }) {
	const { state, dispatch } = React.useContext(Store);
	const [
		termoAceito,
		toggleAceite
	] = useState(false);
	const handleTermoClique = () => {
		toggleAceite(!termoAceito);
	};

	const handlePainelClique = () => {
		submitUnsubscribe(dispatch, tipoProcesso);
	};
	const classActiveButton = termoAceito ? 'finalizar-botao-ativo' : 'finalizar-botao-inativo';

	const iconeCheckbox = termoAceito ? (
		<CheckedIcon className='unsubscribe-checkbox' onClick={handleTermoClique} />
	) : (
		<SquareIcon className='unsubscribe-checkbox' onClick={handleTermoClique} />
	);

	const renderBotaoFinalizar = () => (
		<div
			className={`finalizar-botao-wrapper ${classActiveButton}`}
			onClick={handlePainelClique}
			onKeyUp={() => {}}
			role='button'
			tabIndex='0'
		>
			<CheckIcon className='finalizar-botao-icon' />
			<div className='finalizar-botao-texto'>Confirmar</div>
		</div>
	);

	if (state.sentUnsubscribe) {
		return (
			<div className='painel-unsubscribe-wrapper'>
				{renderMensagemDeConfirmacao()}
				{renderMensagemCompleta()}
				{renderLinkIBCapital()}
			</div>
		);
	}
	return (
		<div className='unsubscribe-painel-wrapper'>
			{renderTituloMensagem()}
			<div className='unsubscribe-wrapper'>
				{iconeCheckbox}
				{renderMensagem()}
			</div>
			{renderBotaoFinalizar()}
		</div>
	);
}
export default SubmitUnsubscribe;
