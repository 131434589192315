import { getDocumentosRepo, postFile, remove } from '../Repositories/Documentos';

import { User } from '../../Common/Helpers/AuthHelper';

export async function getDocumentos(tipoProcesso) {
	try {
		const data = await getDocumentosRepo(tipoProcesso);
		const response = {
			documentos: data.data.documentos,
			painelEnviado: data.data.envioDeDocumentosObrigatoriosFinalizado,
			etapa: data.data.etapa
		};
		return Promise.resolve(response);
	} catch (error) {
		return Promise.reject(error);
	}
}

function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});
}

export const setDocumentos = async (documentoId, file, listaDocumentos, tipoProcesso) => {
	const fileSent = file;
	try {
		const fileBase64 = await getBase64(fileSent);
		const data = {
			idProcesso: User.get().idProcesso,
			idDocumento: documentoId,
			documentos: [
				{
					nomeArquivo: fileSent.name,
					arquivo: fileBase64,
					contentType: fileSent.type
				}
			]
		};
		let response = await postFile(data, tipoProcesso);
		fileSent.status = 'enviado';
		fileSent.idArquivo = response.data.idArquivo;
		fileSent.canBeRemoved = true;
	} catch (e) {
		fileSent.status = 'erro';
	}
	const documento = listaDocumentos.filter(item => item.idDocumento === documentoId)[0];
	documento.enviado = true;

	documento.files = documento.files
		? [
				...documento.files,
				fileSent
			]
		: [
				fileSent
			];
	return listaDocumentos;
};

export const removeFile = async (documentoId, file, listaDocumentos, tipoProcesso) => {
	const fileSent = file;

	const bodyRequest = {
		idArquivo: file.idArquivo,
		tipoProcesso: tipoProcesso,
		idDocumento: documentoId
	};

	try {
		await remove(bodyRequest);
	} catch (e) {
		fileSent.status = 'erro';
	}
	const documento = listaDocumentos.filter(item => item.idDocumento === documentoId)[0];
	documento.enviado = true;
	documento.files = documento.files.filter(files => files.idArquivo !== fileSent.idArquivo);
	return listaDocumentos;
};
