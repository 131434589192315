import axios from 'axios'; // biblioteca de requisições http
import { BFF_URL } from '../../Common/Helpers/ApiHelper'; // caminho do BFF

export const confirmation = (resposta, token) => {
	const url = `${BFF_URL}/email-commercials`; // caminho para o lambda de confirmação de renovação
	const options = {
		headers: { Authorization: 'Bearer ' + token }
	};
	return new Promise((resolve, reject) => {
		axios
			.post(url, { resposta }, options)
			.then(result => {
				resolve(result);
			})
			.catch(error => {
				reject(error);
			});
	}); // retorna uma promisse da requisição
};
