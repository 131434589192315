import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useAppContext } from '../../Infrastructure/Store/Store';
import { faCheck, faHourglassHalf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './TimelineStep.scss';

function TimelineStep(props) {
    const { step, nextStepNotChecked } = props;
    const initialFiles = step ? step : [];
    useState(initialFiles);

    function getClassName() {
        let classes = '';
        if (step.currentStep)
            classes += ' timeline-step-circle-wrapper-checked';
        if (!step.currentStep && nextStepNotChecked)
            classes += ' timeline-step-circle-wrapper-after';
        if (step.isFirst)
            classes += ' timeline-step-circle-wrapper-first';
        return classes;
    };

    function getIcon() {
        if (!step.currentStep && !nextStepNotChecked)
            return <FontAwesomeIcon icon={faCheck} />;
        if (step.currentStep && nextStepNotChecked)
            return <FontAwesomeIcon icon={faHourglassHalf} />;
        return <> </>;
    }

    if (step) {
        return (
            <div className="timeline-step-wrapper">
                <div className="timeline-step-hr-wrapper" />
                <div className={"timeline-step-circle-wrapper " + (getClassName(step))}>
                    {getIcon()}
                </div>

                <span className={ !step.currentStep && nextStepNotChecked ? "timeline-step-name-wrapper timeline-step-name-wrapper-after" : "timeline-step-name-wrapper" }>{step.name}</span>
            </div>
        );
    }
    return (<>
        <div className="timeline-step-loading-wrapper" />
    </>);
};

TimelineStep.propTypes = {
    step: PropTypes.object,
    nextStepNotChecked: PropTypes.bool
};

TimelineStep.defaultProps = {
    step: null,
    nextStepNotChecked: false
};

export default TimelineStep;
