import { confirmationService } from '../Services/RenewalConfirmation';

export const sendConfirmation = async (dispatch, resposta, token) => {
	try {
		const data = await confirmationService(resposta, token);
		if (data) {
			dispatch({
				type: 'RENEWAL_CONFIRMATION',
				payload: data
			});
			return data;
		}
	} catch (e) {
		dispatch({
			type: 'REQUEST_ERROR'
		});
	}
};

export const errorParams = dispatch => {
	dispatch({
		type: 'REQUEST_ERROR',
		payload: 'URL inválida: erro de parâmetros.'
	});
};
