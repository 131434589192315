import React, { useState, useEffect } from 'react';
import Header from 'Component/Header/index';
import LinkAcesso from '../../Common/Assets/images/link-access.svg';
import { ReactComponent as Spinner } from '../../Common/Assets/images/white-Spinner.svg';
import Buttonn from '../../Component/Button/Button';
import { Container } from '@material-ui/core';
import { Store } from '../../Infrastructure/Store/Store';
import { Redirect } from 'react-router-dom';
import SimpleModal from '../../Component/Modal/SendEmail'
import { submitValidateCode } from '../../Infrastructure/Actions/ValidateCode';
import Alert from '../../Component/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { cleanCodeError } from '../../Infrastructure/Actions/Code';
import  InputError  from '../../Component/InputError';

import './Code.scss';

const darkGray = '#4F4F4F';
const dimGray = '#696969';

const useStyles = makeStyles(() => ({
  backButton: {
    padding: '12px 50px',
    marginRight: '40px',
    backgroundColor: dimGray,
    borderColor: dimGray,
    '&:hover': {
      backgroundColor: darkGray,
      borderColor: darkGray,
      boxShadow: darkGray,
    },
    '&:focus': {
      backgroundColor: dimGray,
      borderColor: dimGray,
      boxShadow: dimGray,
    },
  }
}));

function Codigo() {
  const {state, dispatch} = React.useContext(Store);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(true);
  const [code, setCode] = useState('');
  const [back, setBack] = useState(false);
  const classes = useStyles();
  
  useEffect(() => { 
    setLoading(false);
    handleCodeError()
  },[state.validCode]);

  const handleCodeError = () => {
    if (state.validCode === false) {
      setCode(''); 
      setError(true);
    }
  }

  const updateData = e => {
    e.target.value = e.target.value.substring(0, 4).replace(/\D/g,"");
    setCode(e.target.value)

    if(e.target.value.length === 4) 
      setError(false);
    else 
      setError(true);
  }

  const validateCode = async () => {
    await cleanCodeError(dispatch);

    setLoading(true);
    submitValidateCode(dispatch, {code:code, email:state.email || getEmailLocalStorage()})
  }

  const getEmailLocalStorage = () => {
    const USER_KEY = process.env.REACT_APP_IB_CAPITAL_USER_KEY;
    return JSON.parse(localStorage.getItem(USER_KEY)).email
  }

  const renderCustomInputCode = () => {
    return <div className="align-column"> 
    <div className="code-page__code">
        <div id="divOuter">
          <div id="divInner">
            <input 
              id="partitioned"
              className={state.validCode === false? 'error-partitioned': ''} 
              type="text" 
              maxLength="4"
              onChange={updateData} 
              data-cy="input-code"
              autoFocus={true}
              value={code}
              placeholder="0000"
              autoComplete="off"
            />
          </div>
        </div>
      </div>
      <InputError
          className="input-error_center"
          error={state.validCode === false}
          value={true}
          fieldName={"Código digitado"}
        />
    </div>
  }

  const goBack = () => {
    setBack(true)
  }

  if(state.validCode) {
    return <Redirect to={{ pathname: '/' }} />
  }

  if (back) {
    return <Redirect to={{ pathname: '/home' }} />
  }

  return (
    <div className="page_background">
      <Header />
      <Alert />
      <Container className="content-page code-page">
        <div className="content-page__inner">
          <p 
            className="code-page__instruction code-page__instruction_space" 
            data-cy="instruction"
            >Insira abaixo o código enviado para o seu e-mail
          </p>
          <div className="code-page__image code-page__image_space">
            <img 
              src={LinkAcesso} 
              className="code-page__image_responsive" 
              data-cy="image"
              alt="Image de um envelope com uma folha de papel saindo dele"
            />
          </div>
          {renderCustomInputCode()}
          <div className={`code-page__buttons_space ${state.validCode || state.validCode === ''  ? 'code-page__code_space' : 'code-page__code_space-error'}`}>
            <div><Buttonn 
                className={classes.backButton}
                onClick={goBack}
              ><span>Voltar</span>
              </Buttonn></div>
            <div className="button code-page__button code-page__button_space">
              <Buttonn 
                disabled={error}
                onClick={validateCode}
                data-cy="btn-submit"
                ><span>{loading ? <div className="code-page__white-spinner"><Spinner className="submit-loading-button-icon"/> Confirmar </div> : <div className="align-spinner"> Confirmar </div>}</span>
              </Buttonn>
            </div>
          </div>
        </div>
      <SimpleModal/>
      </Container>
    </div>
  );
}

export default Codigo;
