import { submitForm } from '../Repositories/Form';

export const submit = async (tipoProcesso, etapaDeProcesso) => {
	try {
		if (tipoProcesso === 'abertura' && etapaDeProcesso === null) throw 'Parâmetro inválido';
		const response = await submitForm(tipoProcesso, etapaDeProcesso);
		return response.data && response.data.valid;
	} catch (error) {
		throw error;
	}
};

export default null;
