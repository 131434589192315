import axios from 'axios';
import { BFF_URL } from '../../Common/Helpers/ApiHelper';

export const submitCheckEmail = (email) => {
  const url = `${BFF_URL}/email`;
  
  return new Promise((resolve, reject) => {
    const body = {'email': email};
    
    axios.post(url, body).then(
      result => resolve(result),
    ).catch((error) => {
        reject(error);
    });
  });
};
