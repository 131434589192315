import { getContatoRepo } from '../Repositories/Cliente';
import { User } from '../../Common/Helpers/AuthHelper';

export async function getUser(tipoProcesso) {
	const response = await getContatoRepo(tipoProcesso);
	const { contato } = response.result.data;
	const { email, razaoSocial, nome } = contato;
	const { idProcesso } = response.result.data;
	const { jwtToken } = response;
	User.save({
		email,
		razaoSocial,
		idProcesso,
		nome,
		jwtToken,
		tipoProcesso
	});
	return { razaoSocial, idProcesso, nome };
}

export default null;
