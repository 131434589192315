import React, {useEffect, useState, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import LinkAcesso from '../../Common/Assets/images/send-email.svg';
import Buttonn from '../../Component/Button/Button';
import { Store } from '../../Infrastructure/Store/Store';
import CleanEmail from '../../Infrastructure/Actions/CleanEmail'

import './SendEmail.scss'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: "30px 40px 40px 40px",
    backgroundColor: theme.palette.background.paper,
    borderRadius:"4%",
    border: "0px",
    boxShadow: theme.shadows[6],
  },
  checkEmail__button_shape: {
    padding: "7px 40px 7px 40px",
},
}));

export default function SimpleModal() {
  const { state, dispatch} = useContext(Store);
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(()=>{
    handleOpen();
  },[state])

  const handleOpen = () => {
    if(state.existsEmail){
      setOpen(true);
      CleanEmail(dispatch)
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        className="modal"
        data-cy="modal"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          
          <div className={classes.paper}>
          <div className="closeTag">
            <span className="closeTag_cursor" onClick={handleClose}>X</span>
          </div>
          <div className="checkEmail__image">
            <img 
                src={LinkAcesso}
                className="checkEmail__image_responsive" 
                data-cy="image"
            />
          </div>
            <h2 className="checkEmail__title" data-cy="model-title">Verifique sua caixa de entrada!</h2>
            <p className="checkEmail__instruction" data-cy="model-instruction" >Em alguns instantes você receberá um código de acesso</p>
            <div className="checkEmail__button">
                <Buttonn 
                    className={classes.checkEmail__button_shape}
                    onClick={handleClose}
                    data-cy="model-btn"
                > <span className="checkEmail__button_inner"> Entendi </span>
                </Buttonn>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}