import { submitSearchCNPJ } from '../Repositories/SearchCNPJ';

export const submitSearchCNPJRequest = async (cnpj) => {
  const response = await submitSearchCNPJ(cnpj);
  return {
    razaoSocial: response.data.empresa.razaoSocial,
    nomeFantasia: response.data.empresa.nomeFantasia,
    telefone: response.data.empresa.telefone,
  };
};

export default null;
