import { getStepsRepo } from '../Repositories/Steps';

export async function getSteps(tipoProcesso) {
	try {
		const data = await getStepsRepo(tipoProcesso);
		const response = {
			steps: data.data.etapasAtualizadas
		};
		return Promise.resolve(response);
	} catch (error) {
		return Promise.reject(error);
	}
}
