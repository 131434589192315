import React, { useState, useLayoutEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { setFileToDocument, removeFiles } from '../../Infrastructure/Actions/Documentos';

import { useAppContext } from '../../Infrastructure/Store/Store';

import { ReactComponent as FileUploadIcon } from '../../Common/Assets/images/fileUpload.svg';
import { ReactComponent as CheckCircleIcon } from '../../Common/Assets/images/checkCircle.svg';

import { ReactComponent as ErrorCircleIcon } from '../../Common/Assets/images/errorIcon.svg';
import { ReactComponent as CloseIcon } from '../../Common/Assets/images/timesIcon.svg';

import './InputDocumento.scss';

function InputDocumento(props) {
	const { documento, tipoProcesso } = props;
	const initialFiles = documento ? documento.files : [];
	const [
		selectedFiles,
		setSelectedFiles
	] = useState(initialFiles);
	const [
		modalOpen,
		setModalOpen
	] = useState(false);
	const { state, dispatch } = useAppContext();

	const getUploadedFileName = e => {
		const files = Array.from(e.target.files);
		const newSelectedFiles = documento.files
			? [
					...documento.files,
					...files
				]
			: files;
		setSelectedFiles(newSelectedFiles);
		files.map(file =>
			setFileToDocument(documento.idDocumento, file, state.listaDocumentos, dispatch, tipoProcesso)
		);
		e.target.value = null;
	};

	const styleInputWrapper =
		selectedFiles && selectedFiles.length > 0 ? { border: '1px solid #89D37F' } : {};

	const formatFileSize = (bytes, decimalPoint) => {
		if (bytes === 0) return '0 Bytes';
		const k = 1000;
		const dm = decimalPoint || 2;
		const sizes = [
			'Bytes',
			'KB',
			'MB',
			'GB',
			'TB',
			'PB',
			'EB',
			'ZB',
			'YB'
		];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return `${parseFloat(bytes / (k ** i).toFixed(dm))} ${sizes[i]}`;
	};

	let inputElement;

	const handleClick = e => {
		inputElement.click(e);
	};

	const renderFile = file => {
		const removeFile = e => {
			removeFiles(documento.idDocumento, file, state.listaDocumentos, dispatch, tipoProcesso);
			const newSelectedFiles = documento.files.filter(files => files.idArquivo !== file.idArquivo);
			setSelectedFiles(newSelectedFiles);
		};

		if (file.status) {
			if (file.status === 'enviado') {
				if (file.canBeRemoved)
					return (
						<Fragment>
							<div className='file-wrapper'>
								<CheckCircleIcon className='file-uploaded-icon success' />
								<div className='file-uploaded-name success'>{file.name}</div>
								<div className='file-uploaded-size'>({formatFileSize(file.size)})</div>
							</div>
							<div
								className='file-remove'
								value={file.nome}
								onClick={removeFile}
								onKeyUp={() => {}}
								role='button'
								tabIndex='0'
							>
								Remover Arquivo
							</div>
						</Fragment>
					);
				else
					return (
						<Fragment>
							<div className='file-wrapper'>
								<CheckCircleIcon className='file-uploaded-icon success' />
								<div className='file-uploaded-name success'>{file.name}</div>
								<div className='file-uploaded-size'>({formatFileSize(file.size)})</div>
							</div>
						</Fragment>
					);
			}
			return (
				<Fragment>
					<div className='file-wrapper'>
						<ErrorCircleIcon className='file-uploaded-icon error' />
						<div className='file-uploaded-name error'>{file.name}</div>
						<div className='file-uploaded-size'>({formatFileSize(file.size)})</div>
					</div>
				</Fragment>
			);
		}
		return (
			<Fragment>
				<div className='file-wrapper'>
					<div className='loader' />
					<div className='file-uploaded-name'>{file.name}</div>
					<div className='file-uploaded-size'>({formatFileSize(file.size)})</div>
				</div>
			</Fragment>
		);
	};

	const renderInputTitulo = () => {
		if (documento) {
			return (
				<Fragment>
					<div className='input-title-documento'>{documento.nome}</div>
					<div className='input-title-descricao'>{documento.observacao}</div>
					<div
						className='input-title-details'
						onClick={() => setModalOpen(true)}
						onKeyUp={() => {}}
						role='button'
						tabIndex='0'
					>
						<p>ver detalhes</p>
					</div>
				</Fragment>
			);
		}
		return (
			<Fragment>
				<div className='input-title-documento-loading' />
				<div className='input-title-details-loading' />
			</Fragment>
		);
	};

	const renderInputBotao = () => {
		if (documento) {
			return (
				<div
					className='input-enviar-arquivo'
					onClick={documento ? handleClick : null}
					onKeyUp={() => {}}
					role='button'
					tabIndex='0'
				>
					<input
						ref={input => {
							inputElement = input;
							return true;
						}}
						type='file'
						accept='.pdf,.jpg,.jpeg,.png'
						className='file-upload'
						onChange={getUploadedFileName}
						multiple
					/>
					<div className='input-icon'>
						<FileUploadIcon className='input-icon-svg' />
					</div>
					<div className='input-text'>Anexar arquivo(s)</div>
				</div>
			);
		}
		return <div className='input-enviar-arquivo-loading' />;
	};

	const renderInputHeader = () => (
		<div className='input-documento-header'>
			<div className='wrapper-input-documento'>{renderInputTitulo()}</div>
			{renderInputBotao()}
		</div>
	);

	const renderFilesUploaded = () => {
		if (documento) {
			return (
				<div className='upload-files-selected'>
					{selectedFiles &&
						selectedFiles.map((file, index) => (
							<div className='file-uploaded-wrapper' key={index}>
								{renderFile(file)}
							</div>
						))}
				</div>
			);
		}
		return null;
	};

	const renderInputContent = () => (
		<div className='wrapper-input-content'>
			{renderInputHeader()}
			{renderFilesUploaded()}
		</div>
	);

	const renderModal = () => {
		if (modalOpen) {
			return (
				<Modal
					titulo={documento.nome}
					detalhes={documento.detalhes}
					onClose={() => setModalOpen(false)}
					opened={modalOpen}
				/>
			);
		}
		return null;
	};

	return (
		<div className='input-wrapper' style={styleInputWrapper}>
			{renderInputContent()}
			{renderModal()}
		</div>
	);
}

function useLockBodyScroll() {
	useLayoutEffect(() => {
		// Get original body overflow
		const originalStyle = window.getComputedStyle(document.body).overflow;
		// Prevent scrolling on mount
		document.body.style.overflow = 'hidden';
		// Re-enable scrolling when component unmounts
		return () => {
			document.body.style.overflow = originalStyle;
			return true;
		};
	}, []);
}

function Modal({ titulo, detalhes, onClose, opened }) {
	useLockBodyScroll();
	const modalDetalhesStyle = opened ? { display: 'flex' } : { display: 'none' };
	return (
		<div
			className='input-modal-detalhes-wrapper'
			style={modalDetalhesStyle}
			onClick={onClose}
			onKeyUp={() => {}}
			role='button'
			tabIndex='0'
		>
			<div
				className='modal-detalhes-info-wrapper'
				onClick={e => {
					e.stopPropagation();
				}}
				onKeyUp={() => {}}
				role='button'
				tabIndex='0'
			>
				<CloseIcon className='modal-detalhes-close-icon' onClick={onClose} />
				<div className='modal-detalhes-titulo'>{titulo}</div>
				<div className='modal-detalhes-texto'>{detalhes}</div>
				<div
					className='modal-detalhes-botao'
					onClick={onClose}
					onKeyUp={() => {}}
					role='button'
					tabIndex='0'
				>
					<p>Entendi</p>
				</div>
			</div>
		</div>
	);
}

Modal.propTypes = {
	titulo: PropTypes.string.isRequired,
	detalhes: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
	opened: PropTypes.bool.isRequired
};

InputDocumento.propTypes = {
	documento: PropTypes.object
};

InputDocumento.defaultProps = {
	documento: null
};

export default InputDocumento;
