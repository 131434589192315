import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Header from 'Component/Header/index';
import { Container } from '@material-ui/core';
import { ReCaptcha } from 'react-recaptcha-google';
import Alert from '../../Component/Alert';
import CssTextField from '../../Component/Input/CssTextField';
import SelectInput from '../../Component/Select/SelectField';
import Buttonn from '../../Component/Button/Button';
import Image from '../../Common/Assets/images/form.svg';

import { Store } from '../../Infrastructure/Store/Store';
import { ReactComponent as Spinner } from '../../Common/Assets/images/white-Spinner.svg';
import { cnpjMask, phoneMask } from '../../Common/Helpers/Masks';
import { validateCNPJ, validateEmail, validatePhone } from '../../Common/Helpers/Validations';
import { submitRegisterLead, getAgentesComerciais } from '../../Infrastructure/Actions/Register';
import TypeWriter from '../../Component/TypeWriter';
import InputError from '../../Component/InputError';
import { submitSearchCNPJ } from '../../Infrastructure/Actions/SearchCNPJ';

import './Register.scss';

function Register() {
  const { state, dispatch } = React.useContext(Store);
  const [leadData, setLeadData] = useState({ email: state.email });
  const [error, setError] = useState({
    errorCorporateName: null, errorName: null, errorCnpj: null, errorRepeatEmail: null, errorPhone: null,
  });
  const [loading, setLoading] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [paste, setPaste] = useState({ pasting: false, beforePasting: '' });
  const [corporateName, setCorporateName] = useState('');

  useEffect(() => {
    getAgentesComerciais(dispatch);
  }, []);

  useEffect(() => {
    setCorporateName(state.razaoSocial);
    setLeadData({
      ...leadData,
      corporateName: state.razaoSocial,
    });

    setError({
      ...error,
      errorCorporateName: false,
    });
  }, [state.getCnpjData]);

  useEffect(() => {
    setLoading(false);
  }, [state]);

  const updateCorporateName = (event) => {
    setCorporateName(event.target.value);
    updateData(event);
  };

  const validateCorporateName = (event) => {
    if (!event || !event.target || !event.target.value) {
      setError({
        ...error,
        errorCorporateName: true,
      });
    } else {
      setError({
        ...error,
        errorCorporateName: false,
      });
    }
  };

  const validateName = (event) => {
    if (!event || !event.target || !event.target.value) {
      setError({
        ...error,
        errorName: true,
      });
    } else {
      setError({
        ...error,
        errorName: false,
      });
    }
  };

  const validateCNPJInput = (event) => {
    if (!event || !event.target) { return; }

    setError({
      ...error,
      errorCnpj: !validateCNPJ(event.target.value),
    });

    if (validateCNPJ(event.target.value) && leadData.oldCNPJ !== event.target.value) {
      submitSearchCNPJ(dispatch, leadData.cnpj);
      setLeadData({
        ...leadData,
        oldCNPJ: leadData.cnpj,
      });
    }
  };

  const validateEmailInput = (event) => {
    if (!event || !event.target) { return; }

    if (leadData.email === leadData.repeatEmail) {
      setError({
        ...error,
        errorEmail: !validateEmail(event.target.value),
        errorRepeatEmail: false,
      });
    } else {
      setError({
        ...error,
        errorEmail: !validateEmail(event.target.value),
        errorRepeatEmail: true,
      });
    }
  };

  const validatePhoneInput = (event) => {
    if (!event || !event.target) { return; }

    setError({
      ...error,
      errorPhone: !validatePhone(event.target.value),
    });
  };

  const validateEqualEmails = () => {
    if (leadData.email === leadData.repeatEmail) {
      setError({
        ...error,
        errorRepeatEmail: false,
      });
    } else {
      setError({
        ...error,
        errorRepeatEmail: true,
      });
    }
  };

  const handleCnpjMask = (event) => {
    if (!event || !event.target || !event.target.value) {
      setLeadData({
        ...leadData,
        [event.target.name]: '',
      });
      return;
    }
    setLeadData({
      ...leadData,
      [event.target.name]: cnpjMask(event.target.value),
    });
    event.target.value = cnpjMask(event.target.value);
  };

  const handlePhoneMask = (event) => {
    if (!event || !event.target || !event.target.value) {
      setLeadData({
        ...leadData,
        [event.target.name]: '',
      });
      return;
    }
    setLeadData({
      ...leadData,
      [event.target.name]: phoneMask(event.target.value),
    });
    event.target.value = phoneMask(event.target.value);
  };

  const registerLead = () => {
    setLoading(true);

    if (isVerify) {
      submitRegisterLead(dispatch, {
        nome: leadData.name,
        cnpj: leadData.cnpj,
        razaoSocial: leadData.corporateName,
        email: leadData.email,
        telefone: state.telefone ? `${leadData.phone}` + ' | ' + `${state.telefone}` : `${leadData.phone}`,
        idAgenteComercial: leadData.idAgentCommercial,
        nomeFantasia: state.nomeFantasia,
      });
    }
  };

  const updateData = (e) => {
    setLeadData({
      ...leadData,
      [e.target.name]: e.target.value,
    });
  };

  const updateDataRepeatEmail = (e) => {
    if (paste.pasting) {
      setPaste({
        ...paste,
        pasting: false,
      });

      e.target.value = paste.beforePasting;
    }

    setLeadData({
      ...leadData,
      [e.target.name]: e.target.value,
    });
  };

  const pasting = (e) => {
    setPaste({
      pasting: true,
      beforePasting: e.target.value,
    });
  };

  const droping = (e) => {
    setPaste({
      pasting: true,
      beforePasting: e.target.value,
    });
  };

  const updateValueSelect = (value) => {
    setLeadData({
      ...leadData,
      idAgentCommercial: value,
    });
  };

  const verifyCallback = (response) => {
    if (response.length) {
      setIsVerify(true);
    }
  };

  const onloadCallback = () => {
    if (process.env.REACT_APP_IB_CAPITAL_AMBIENTE === 'CYPRESS') {
      setIsVerify(true);
    }
  };

  const isThereAnyMistake = () => (error.errorCnpj || error.errorEmail || error.errorPhone || error.errorRepeatEmail
      || !corporateName || !leadData.name || !leadData.phone || !leadData.idAgentCommercial);


  const renderReCaptcha = () => (
    <ReCaptcha
      hl="pt-BR"
      sitekey="6LfjTsAUAAAAAI8h0e7GM8qh91amg9CH5klOR8In"
      onloadCallback={onloadCallback}
      verifyCallback={verifyCallback}
      render="explicit"
    />
  );

  if (state.successfulRegistration) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  return (
    <div className="page_background">
      <Header />
      <Alert />
      <Container className="container">
        <div className="register-page__title_space">
          <TypeWriter
            phrase="Conte um pouco sobre sua empresa"
            timer="75"
            css="title"
            complement="appear_4s"
          />
        </div>
        <div className="container-grid">
          <div className="image fade-in_3s">
            <img
              src={Image}
              className="responsive"
              data-cy="image"
            />
          </div>
          <div className="form">
            <div className="fade-in_3s">
              <CssTextField
                autoFocus
                data-cy="name"
                label="Como você deseja ser chamado?"
                type="text"
                name="name"
                id="name"
                margin="dense"
                variant="outlined"
                onChange={updateData}
                onBlur={validateName}
                fullWidth
                error={error.errorName}
              />
              <InputError
                error={error.errorName}
                value={leadData.name}
                fieldName="como você deseja ser chamado"
              />
              <CssTextField
                className="textField"
                label="CNPJ"
                type="text"
                name="cnpj"
                id="cnpj"
                margin="dense"
                variant="outlined"
                maxLength="18"
                onChange={updateData}
                onChange={handleCnpjMask}
                onBlur={validateCNPJInput}
                fullWidth
                error={error.errorCnpj}
                data-cy="cnpj"
              />
              <InputError
                error={error.errorCnpj}
                value={leadData.cnpj}
                fieldName="CNPJ"
              />
              <CssTextField
                className="textField"
                label="Qual a razão social da empresa?"
                type="text"
                name="corporateName"
                id="corporateName"
                margin="dense"
                variant="outlined"
                value={corporateName}
                onChange={updateCorporateName}
                onBlur={validateCorporateName}
                color="secondary"
                fullWidth
                error={error.errorCorporateName}
              />
              <InputError
                error={error.errorCorporateName}
                value={leadData.corporateName}
                fieldName="razão social"
              />
              <CssTextField
                className="textField"
                label="E-mail"
                type="email"
                name="email"
                value={leadData.email}
                id="email"
                margin="dense"
                variant="outlined"
                onChange={updateData}
                onBlur={validateEmailInput}
                fullWidth
                error={error.errorEmail}
              />
              <InputError
                error={error.errorEmail}
                value={leadData.email}
                fieldName="email"
              />
              <CssTextField
                className="textField"
                label="Repetir e-mail"
                type="email"
                name="repeatEmail"
                id="repeatEmail"
                margin="dense"
                variant="outlined"
                maxLength="18"
                onChange={updateDataRepeatEmail}
                onBlur={validateEqualEmails}
                error={error.errorRepeatEmail}
                fullWidth
                onPaste={pasting}
                ondrop={droping}
                autoComplete="new-password"
              />
              <InputError
                error={error.errorRepeatEmail}
                value={leadData.repeatEmail}
                fieldName="email repetido"
              />
              <CssTextField
                className="textField"
                label="Telefone"
                type="text"
                name="phone"
                id="phone"
                margin="dense"
                variant="outlined"
                maxLength="18"
                onChange={handlePhoneMask}
                onBlur={validatePhoneInput}
                fullWidth
                error={error.errorPhone}
              />
              <InputError
                error={error.errorPhone}
                value={leadData.phone}
                fieldName="telefone"
              />
              <SelectInput
                label="Possui relacionamento com algum gerente?"
                itens={state.agentesComerciais}
                itemDefaultSelect="0"
                updateValueSelect={updateValueSelect}
                selectName="idAgentCommercial"
              />
              <div className="robot-button">
                {renderReCaptcha()}
                <div className="button button-right button-out">
                  <Buttonn
                    disabled={isThereAnyMistake() || !isVerify}
                    onClick={registerLead}
                    data-cy="register"
                  ><span>{loading ? <div className="register-page__white-spinner"><Spinner className="submit-loading-button-icon" /> Continuar </div> : <div className="align-spinner"> Continuar </div>}</span>
                  </Buttonn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Register;
