import axios from 'axios';
import { User } from '../../Common/Helpers/AuthHelper';
import {
	BFF_URL,
	SYDLE_SEND_FILE_URL,
	SYDLE_SEND_FILE_URL_RENOVACAO
} from '../../Common/Helpers/ApiHelper';

export const getDocumentosRepo = tipoProcesso => {
	const token = User.get().jwtToken;
	const url = `${BFF_URL}/documents?tipoProcesso=${tipoProcesso}`;
	const header = {
		headers: { Authorization: `Bearer ${token}` }
	};

	return new Promise((resolve, reject) => {
		axios.get(url, header).then(result => resolve(result)).catch(error => {
			reject(error);
		});
	});
};

export const postFile = (data, tipoProcesso) => {
	const header = {
		headers: { Authorization: `Bearer ${process.env.REACT_APP_IB_CAPITAL_SYDLE_SEND_FILE_TOKEN}` }
	};
	return new Promise((resolve, reject) => {
		axios
			.post(
				tipoProcesso === 'renovacao' ? SYDLE_SEND_FILE_URL_RENOVACAO : SYDLE_SEND_FILE_URL,
				data,
				header
			)
			.then(result => resolve(result))
			.catch(error => {
				reject(error);
			});
	});
};

export const remove = data => {
	const token = User.get().jwtToken;
	const url = `${BFF_URL}/removeFile`;
	const header = {
		headers: { Authorization: `Bearer ${token}` }
	};
	return new Promise((resolve, reject) => {
		axios.post(url, data, header).then(result => resolve(result)).catch(error => {
			reject(error);
		});
	});
};
