import React, { useEffect } from 'react';
import { Container } from '@material-ui/core';
import Header from '../../Component/Header';
import SubmitUnsubscribe from '../../Component/SubmitUnsubscribe';
import { Store } from '../../Infrastructure/Store/Store';
import { fetchCliente } from '../../Infrastructure/Actions/Cliente';
import Alert from '../../Component/Alert';

function Unsubscribe() {
	let tipoProcesso = new URLSearchParams(window.location.search).get('tipoProcesso');

	return (
		<div>
			<Header />
			<Alert />
			<Container>
				<SubmitUnsubscribe tipoProcesso={tipoProcesso} />
			</Container>
		</div>
	);
}

export default Unsubscribe;
