import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Redirect } from 'react-router-dom';

import { Container } from '@material-ui/core';
import { Store } from '../../Infrastructure/Store/Store';
import { fetchDataAction } from '../../Infrastructure/Actions/Documentos';
import { fetchCliente } from '../../Infrastructure/Actions/Cliente';

import Header from '../../Component/Header';
import Alert from '../../Component/Alert';
import ListaInputDocumentos from '../../Component/ListaInputDocumentos';
import { User } from '../../Common/Helpers/AuthHelper';

import './Documents.scss';

function Documents(props) {
	const { state, dispatch } = useContext(Store);
	const { painelEnviado } = state;

	let tipoProcesso = User.get().tipoProcesso;

	useEffect(
		() => {
			fetchCliente(dispatch, tipoProcesso);
		},
		[
			dispatch
		]
	);

	useEffect(
		() => {
			fetchDataAction(dispatch, tipoProcesso);
		},
		[
			dispatch
		]
	);

	const { location } = props;
	if (painelEnviado) {
		return <Redirect to={{ pathname: '/acompanhamento', state: { from: location } }} />;
	}

	if (!User.get() || !User.get().jwtToken) {
		return <Redirect to={{ pathname: '/home' }} />;
	}

	return (
		<div>
			<Header />
			<Alert />
			<Container className='content-page home-page'>
				<ListaInputDocumentos
					tipoProcesso={tipoProcesso}
					contato={state.contato}
					idProcesso={state.idProcesso}
					listaDocumentos={state.listaDocumentos}
				/>
			</Container>
		</div>
	);
}

Documents.propTypes = {
	location: PropTypes.object.isRequired
};

export default Documents;
