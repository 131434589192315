import { submitForm, getCommercials } from '../Repositories/Register';
import { errorHandling } from '../../Common/Helpers/ErrorHandling'
import { User } from '../../Common/Helpers/AuthHelper';

export const submit = async (obj) => {
    try {
        const response = await submitForm(obj);
        if(response.status === 200)
            storeTokenInLocalStorage(response.data);
        return response.data
    } catch (error) {
        throw errorHandling(error.response);
    }
};

export const getAgentsCommercials = async () => {
    try {
        return (await getCommercials()).data;
    } catch (error) {
        throw errorHandling(error.response);
    }
};

const storeTokenInLocalStorage = (data) => {
    const jwtToken = data.token;
    
    User.save({
      jwtToken
    })
}

export default null;
