export const cnpjMask = value => {
  return value.substring(0, 18)
    .replace(/\D/g,"")
    .replace(/^(\d{2})(\d)/, "$1.$2")
    .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    .replace(/\.(\d{3})(\d)/, ".$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
}

export const phoneMask = value => {
  return value.substring(0, 15)
    .replace(/\D/g,"")
    .replace(/^(\d{2})(\d)/g,"($1) $2")
    .replace(/(\d)(\d{4})$/,"$1-$2")
}
