import React, { useState } from 'react';
import { Store } from '../../Infrastructure/Store/Store';
import TimelineStep from '../TimelineStep';

import './Timeline.scss';

export const renderTimelineSkeleton = (numeroItens) => {
    const skeleton = [];
    for (let i = 0; i < numeroItens; i += 1) {
        skeleton.push(<TimelineStep key={i} />);
    }
    return skeleton;
};

const RenderTimeline = (steps) => {
    let nextStepNotChecked = false;
    if (steps.length === 0) {
        return renderTimelineSkeleton(5);
    }
    return (
        <>
            {
                steps.map((input, i) => (
                    input && input.currentStep ? nextStepNotChecked = input.currentStep : null,
                    i === 0 ? input.isFirst = true : null,
                    <TimelineStep step={input} nextStepNotChecked={nextStepNotChecked}  key={i} />
                ))
            }
        </>
    );
};

function Timeline() {
    const { state, dispatch } = React.useContext(Store);
    const { steps } = state;

    return (
        <div className="scroll">
        <div className="timeline-wrapper">
            <div className="timeline-steps-wrapper">
                {RenderTimeline(steps)}
            </div>
        </div>
        </div>
    );
}

export default Timeline;
