import { validateCode } from '../Services/ValidateCode';
import { errorHandling } from '../../Common/Helpers/ErrorHandling';

const validateCodePromisse = async (obj) => {
  return await validateCode(obj);
}

export const submitValidateCode = async (dispatch, obj) => {
    validateCodePromisse(obj).then(data => {
      return dispatch({
        type: 'SUBMIT_VALIDATE_CODE',
        payload: data.codigoValido
      });
  }).catch((error) => {
    return dispatch({
      type: 'REQUEST_ERROR',
      payload: error
    });
  });
};

export default null;
