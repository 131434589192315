import React, { useEffect, useState } from 'react';
import { Store } from '../../Infrastructure/Store/Store';
import Email from '../../Common/Assets/images/email.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import { sendConfirmation, errorParams } from '../../Infrastructure/Actions/RenewalConfirmation';
import Header from 'Component/Header/index';
import { ReactComponent as Spinner } from '../../Common/Assets/images/white-Spinner.svg';
import { Fragment } from 'react';
import Alert from 'Component/Alert/index';

function RenewalConfirmation() {
	const { state, dispatch } = React.useContext(Store);
	let resposta;
	let token;

	const [
		show,
		setShow
	] = useState(false);

	const [
		loading,
		setLoading
	] = useState(false);

	useEffect(() => {
		resposta = new URLSearchParams(window.location.search).get('resposta');
		token = new URLSearchParams(window.location.search).get('token');
		if ((resposta !== 'sim' && resposta !== 'nao') || (token === '' || !token)) {
			errorParams(dispatch);
		}
	}, []);

	useEffect(
		() => {
			if (resposta && token) {
				setLoading(true);
				envio();
			}
		},
		[
			resposta,
			token
		]
	);

	useEffect(
		() => {
			if (state.requestError) {
				setLoading(false);
			}
		},
		[
			state.requestError
		]
	);

	const envio = async () => {
		const response = await sendConfirmation(dispatch, resposta === 'sim', token);
		if (response) {
			setShow(true);
			setLoading(false);
		}
	};

	return (
		<div className='page_background'>
			<Header />
			<Alert />

			{!show &&
			loading && (
				<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
					<p data-cy='text-loading' style={{ fontSize: 52, textAlign: 'center' }}>
						Enviando a resposta...
					</p>
					<div data-cy='loading' style={{ textAlign: 'center' }}>
						<CircularProgress style={{ color: '#CD950C' }} />
					</div>
				</div>
			)}

			{show && (
				<Fragment>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<p data-cy='text-feed-back' style={{ fontSize: 52, textAlign: 'center' }}>
							Envio da resposta <br />realizado com sucesso!
						</p>
					</div>
					<div data-cy='image' className='home-page__image'>
						<img
							src={Email}
							className='home-page__image_responsive'
							data-cy='image'
							alt='Image de um envelope'
						/>
					</div>
				</Fragment>
			)}
		</div>
	);
}

export default RenewalConfirmation;
