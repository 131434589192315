import React, { useState, useEffect } from 'react';

const getTimer = (startimer, timer, index) => {
    let starTimer = startimer ? startimer : 0;
    return starTimer ? startimer + timer * index : timer * index;
} 

const renderTitle = (css, title, complement) => {
    return <p className={`${css} dynamic-text`} data-cy="title">{title}<span className={complement}></span></p>
}

function TypeWriter(props){
    const [title,setTitle] = useState('');

    useEffect(() => { 
        typeWriter();
    },[])

    const typeWriter = () => {
        const textArray = props.phrase.split('');
        let dynamicText = '';
        let timer = 0;
        textArray.forEach((letter, index) => {
            timer = getTimer(props.starTimer, props.timer, index);
            setTimeout(()=>{
                dynamicText += letter
                setTitle(dynamicText)
            }, timer)
        })
    }

    return renderTitle(props.css, title, props.complement);
}

export default TypeWriter;