import axios from 'axios';

import { BFF_URL } from '../../Common/Helpers/ApiHelper';
import { User } from '../../Common/Helpers/AuthHelper';

export const getContatoRepo = tipoProcesso => {
	const token = User.get().jwtToken;
	const url = `${BFF_URL}/contacts?tipoProcesso=${tipoProcesso}`;
	const header = {
		headers: { Authorization: 'Bearer ' + token }
	};

	return new Promise((resolve, reject) => {
		axios.get(url, header).then(result => resolve({ result, jwtToken: token })).catch(error => {
			reject(error);
		});
	});
};

export default null;
