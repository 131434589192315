import { submitValidateCode } from '../Repositories/ValidateCode';
import { errorHandling } from '../../Common/Helpers/ErrorHandling';
import { User } from '../../Common/Helpers/AuthHelper';

export const validateCode = async (obj) => {
    try {
        const response = await submitValidateCode(obj);
        if(response.status === 200 && response.data.codigoValido)
            storeTokenInLocalStorage(response.data);
        return response.data
    } catch (error) {
        throw errorHandling(error.response);
    }
};

const storeTokenInLocalStorage = (data) => {
    const jwtToken = data.token;
    
    User.save({
      jwtToken
    })
}

export default null;
