import React, { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';
import Header from '../../Component/Header';
import SubmitNps from '../../Component/SubmitNps';
import { Store } from '../../Infrastructure/Store/Store';
import Alert from '../../Component/Alert';
import { User } from '../../Common/Helpers/AuthHelper';
import { submitEvaluation } from '../../Infrastructure/Actions/Evaluation';

function Nps() {
	const { state, dispatch } = React.useContext(Store);
	const [
		grade,
		setGrade
	] = useState(state.grade);

	const [
		justificativa,
		setJustificativa
	] = useState('');

	const [
		loading,
		setLoading
	] = useState(false);

	const [
		enviado,
		setEnviado
	] = useState(false);

	let newGrade = new URLSearchParams(window.location.search).get('grade');
	const user = User.get();

	useEffect(() => {
		if (newGrade) {
			setGrade(newGrade);
		}
	}, []);

	useEffect(
		() => {
			if (newGrade) {
				User.save({ ...user, grade: newGrade });
			} else if (user.grade) {
				setGrade(user.grade);
			}
		},
		[
			newGrade
		]
	);

	useEffect(
		() => {
			if (grade) {
				submitEvaluation(dispatch, grade, '');
			}
		},
		[
			grade
		]
	);

	useEffect(
		() => {
			setLoading(false);
		},
		[
			state
		]
	);

	useEffect(
		() => {
			if (loading && !enviado) {
				setEnviado(true);
			}
		},
		[
			loading
		]
	);

	const envioJustificativa = async () => {
		await submitEvaluation(dispatch, grade, justificativa);
	};

	return (
		<div>
			<Header />
			<Alert />
			<Container>
				<SubmitNps
					enviado={enviado}
					loading={loading}
					setLoading={setLoading}
					setJustificativa={setJustificativa}
					envioJustificativa={envioJustificativa}
				/>
			</Container>
		</div>
	);
}

export default Nps;
