import { getUser } from '../Services/Cliente';

const getUserPromise = async tipoProcesso => {
	return await getUser(tipoProcesso);
};

export const fetchCliente = async (dispatch, tipoProcesso) => {
	getUserPromise(tipoProcesso)
		.then(data => {
			if (data) {
				return dispatch({
					type: 'FETCH_PROCESSO',
					payload: data
				});
			}
		})
		.catch(() => {
			return dispatch({
				type: 'REQUEST_PROCESSO_ERROR'
			});
		});
};

export default null;
