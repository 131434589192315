import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputLabel, MenuItem, FormControl, Select } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%',
        'margin-top': '8px',
        'margin-bottom': '4px',
        '& .MuiFormLabel-root.Mui-focused': {
            color: '#CD950C'
        },
        '& .MuiSelect-select:focus': {
            'background-color': 'white'
        },
        '& .MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-input': {
                padding: '11.5px 14px'
            },
            '& fieldset': {
                borderColor: 'grey',
                borderWidth: "2px"
            },
            '&:hover fieldset': {
                borderColor: 'grey',
                borderWidth: '2px'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#CD950C',
                borderWidth: '3px'
            }
        }
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    }
}));

export default function SimpleSelect(props) {

    const classes = useStyles();
    const inputLabel = React.useRef(null);
    const [ labelWidth, setLabelWidth ] = useState(0);   
    const [ itemValueSelected, setItemValueSelected ] = useState("");

    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    useEffect(() => {
        updateValue(props.itemDefaultSelect);
    }, [props.itemDefaultSelect]);

    const handleChange = e => {
        updateValue(e.target.value);
    };

    const updateValue = (value) => {
        props.updateValueSelect(value);
        setItemValueSelected(value);
    }
    
    return (
        <FormControl id={props.selectName} variant="outlined" className={classes.formControl}>
            <InputLabel ref={inputLabel}>
                {props.label}
            </InputLabel>
            {props.itens && props.itens.length > 0 ?
            <Select
                value={itemValueSelected}
                name={props.selectName}
                onChange={handleChange}
                labelWidth={labelWidth} >
                {props.itens.map((item, index) =>
                    <MenuItem key={index} value={item._id}>
                        {item.nome}
                    </MenuItem>
                )}
            </Select> : <></>}
        </FormControl>
        
    );
}