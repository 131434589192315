import { submit } from '../Services/Form';

const submitPromisse = async (tipoProcesso, etapaDeProcesso) => {
	return await submit(tipoProcesso, etapaDeProcesso);
};

export const submitForm = async (dispatch, tipoProcesso, etapaDeProcesso) => {
	submitPromisse(tipoProcesso, etapaDeProcesso)
		.then(data => {
			if (data) {
				return dispatch({
					type: 'SUBMIT_FORM',
					payload: data
				});
			}
		})
		.catch(error => {
			return dispatch({
				type: 'REQUEST_ERROR',
				payload: error
			});
		});
};

export default null;
