import axios from 'axios';
import { BFF_URL } from '../../Common/Helpers/ApiHelper';

export const submitValidateCode = (obj) => {
  const url = `${BFF_URL}/validateCode?email=${obj.email}&codigoDeAcesso=${obj.code}`;

  return new Promise((resolve, reject) => {
    axios.get(url).then((result) =>{
        resolve(result)
      }).catch((error) => {
        reject(error);
    });
  });
};
