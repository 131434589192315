import React, { useState, useEffect, Fragment } from 'react';
import InputDocumento from '../InputDocumento';

import { Store } from '../../Infrastructure/Store/Store';
import { submitForm } from '../../Infrastructure/Actions/Form';
import { enableAlertMessage, cleanAlert } from '../../Infrastructure/Actions/Alert';
import { fetchDataAction } from '../../Infrastructure/Actions/Documentos';

import { ReactComponent as SquareIcon } from '../../Common/Assets/images/squareIcon.svg';
import { ReactComponent as CheckedIcon } from '../../Common/Assets/images/checkSquare.svg';
import { ReactComponent as CheckIcon } from '../../Common/Assets/images/checkIcon.svg';
import { ReactComponent as Spinner } from '../../Common/Assets/images/spinner.svg';

import './ListaInputDocumentos.scss';

const renderUsuario = (nome, tipoProcesso) => {
	const nomeSpan = nome ? <div className='nome-usuario'>, {nome}!</div> : '';
	const acao =
		tipoProcesso === 'abertura'
			? 'Fazer o cadastro na IB Capital nunca foi tão fácil!'
			: 'Renovar seu cadastro na IB Capital nunca foi tão fácil!';
	return (
		<div className='usuario-wrapper'>
			<div className='text-identificacao-usuario'>Olá{nomeSpan}</div>
			<div className='text-intrucoes-usuario'>
				{acao}
				<br />Basta enviar seus documentos seguindo as instruções abaixo e aguardar a liberação.
			</div>
		</div>
	);
};

export const renderTabelaSkeleton = numeroItens => {
	const skeleton = [];
	for (let i = 0; i < numeroItens; i += 1) {
		skeleton.push(<InputDocumento key={i} />);
	}
	return skeleton;
};

const RenderTabela = (listaDocumentos, tipoProcesso) => {
	if (listaDocumentos.length === 0) {
		return renderTabelaSkeleton(6);
	}
	return (
		<Fragment>
			{listaDocumentos.map(input => (
				<InputDocumento tipoProcesso={tipoProcesso} documento={input} key={input.idDocumento} />
			))}
		</Fragment>
	);
};

function ListaInputDocumentos({ tipoProcesso }) {
	const { state, dispatch } = React.useContext(Store);
	const [
		termoAceito,
		toggleAceite
	] = useState(false);
	const [
		loadingSubmit,
		setLoadingSubmit
	] = useState(false);
	const [
		error,
		setError
	] = useState(false);
	const { listaDocumentos, etapaDeProcesso } = state;

	useEffect(
		() => () => {
			setLoadingSubmit(false);
			setError(false);
		},
		[
			state.painelEnviado,
			state.requestError,
			state.requestDocumentsError
		]
	);

	const handleTermoClique = () => {
		toggleAceite(!termoAceito);
	};

	const checkSentDocuments = async () => {
		await fetchDataAction(dispatch, tipoProcesso);
		if (
			listaDocumentos &&
			listaDocumentos.filter(item => item.obrigatorio && item.files && item.files.length > 0)
				.length !== listaDocumentos.filter(item => item.obrigatorio).length
		) {
			enableAlertMessage(dispatch, 'Estão faltando documentos a serem enviados.');
		}
	};

	const handlePainelClique = () => {
		cleanAlert(dispatch);
		setLoadingSubmit(true);
		checkSentDocuments();
		submitForm(dispatch, tipoProcesso, etapaDeProcesso);
	};

	let todosEnviados = false;
	if (state.listaDocumentos && state.listaDocumentos.length > 0) {
		todosEnviados = state.listaDocumentos.filter(
			item => item.obrigatorio && item.files && item.files.length > 0
		);
	}

	const formularioPronto = () => {
		let documentosObrigatorios = [];
		documentosObrigatorios = state.listaDocumentos.filter(documento => documento.obrigatorio);
		return documentosObrigatorios.length === todosEnviados.length && termoAceito;
	};

	const funcSubmit = formularioPronto() ? handlePainelClique : () => {};
	const classActiveButton =
		formularioPronto() && (!loadingSubmit || error)
			? 'finalizar-botao-ativo'
			: 'finalizar-botao-inativo';

	const iconeCheckbox = termoAceito ? (
		<CheckedIcon className='aceite-termos-checkbox' onClick={handleTermoClique} />
	) : (
		<SquareIcon className='aceite-termos-checkbox' onClick={handleTermoClique} />
	);

	if (state.painelEnviado) {
		return null;
	}

	return (
		<div className='painel-wrapper'>
			{renderUsuario(state.contato ? state.contato : state.razaoSocial, tipoProcesso)}

			<div className='tabela-documentos'>{RenderTabela(listaDocumentos, tipoProcesso)}</div>
			<div className='aceite-termos-wrapper'>
				{iconeCheckbox}
				<div className='accept-text-terms' onClick={handleTermoClique}>
					Ao marcar o quadro abaixo, confirmo que os dados e documentos inseridos neste sistema são
					verídicos e que entendo que é ilegal usar tais recursos para distribuir e publicar
					informações falsas, difamatória, inexata, abusiva, odiosa, que possa hostilizar outras
					pessoas, empresas, produtos, marcas, marcas registradas ou invadir a privacidade de
					pessoas. Adicionalmente, confirmo que sou o titular, procurador e/ou responsável legal das
					pessoas físicas e jurídicas citadas nas informações e documentos inseridos neste
					formulário. Compreendo que o objetivo deste processo é o de permitir que a IB Capital
					detenha os documentos e avalie os dados necessários em seus processos de proteção ao
					crédito e que, para isso, poderá precisar de novos documentos e/ou informações mais
					detalhadas, até que seja possível fornecer uma resposta adequada e segura. Também confirmo
					o meu consentimento para que a IB Capital utilize os dados pessoais constantes neste
					formulário, com a finalidade de permitir a análise das informações no processo cadastral.
					Entendo que tais dados poderão permanecer arquivados por prazo indeterminado, para
					permitir possíveis fiscalizações e auditorias de órgãos reguladores.
				</div>
			</div>
			<div className='button-end'>
				<div
					className={`finalizar-botao-wrapper ${classActiveButton}`}
					onClick={funcSubmit}
					onKeyUp={() => {}}
					role='button'
					tabIndex={0}
				>
					{loadingSubmit && !error ? (
						<Spinner className='submit-loading-botao-icon' />
					) : (
						<CheckIcon className='finalizar-botao-icon' />
					)}
					<div className='finalizar-botao-texto'>
						Finalizar {tipoProcesso === 'abertura' ? 'Cadastro' : 'Renovação'}
					</div>
				</div>
			</div>
		</div>
	);
}

export default ListaInputDocumentos;
