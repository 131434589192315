import { confirmation } from '../Repositories/RenewalConfirmation';
import { errorHandling } from '../../Common/Helpers/ErrorHandling';

export const confirmationService = async (resposta, token) => {
	try {
		const response = await confirmation(resposta, token);
		return response.data;
	} catch (error) {
		throw errorHandling(error.response);
	}
};
