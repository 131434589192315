import { getDocumentos, setDocumentos, removeFile } from '../Services/Documentos';

const getDocumentosPromisse = async tipoProcesso => {
	return await getDocumentos(tipoProcesso);
};

export const fetchDataAction = async (dispatch, tipoProcesso) => {
	getDocumentosPromisse(tipoProcesso)
		.then(data => {
			if (data) {
				return dispatch({
					type: 'FETCH_DOCUMENTOS',
					payload: data
				});
			}
		})
		.catch(() => {
			return dispatch({
				type: 'REQUEST_DOCUMENTS_ERROR'
			});
		});
};

const setDocumentosPromisse = async (documentoId, file, listaDocumentos, tipoProcesso) => {
	return await setDocumentos(documentoId, file, listaDocumentos, tipoProcesso);
};

export const setFileToDocument = async (
	documentoId,
	file,
	listaDocumentos,
	dispatch,
	tipoProcesso
) => {
	setDocumentosPromisse(documentoId, file, listaDocumentos, tipoProcesso)
		.then(data => {
			if (data) {
				return dispatch({
					type: 'SET_FILE',
					payload: data
				});
			}
		})
		.catch(() => {
			return dispatch({
				type: 'REQUEST_ERROR'
			});
		});
};

const removeFilePromisse = async (documentoId, file, listaDocumentos, tipoProcesso) => {
	return await removeFile(documentoId, file, listaDocumentos, tipoProcesso);
};

export const removeFiles = async (documentoId, file, listaDocumentos, dispatch, tipoProcesso) => {
	removeFilePromisse(documentoId, file, listaDocumentos, tipoProcesso)
		.then(data => {
			if (data) {
				return dispatch({
					type: 'SET_FILE',
					payload: data
				});
			}
		})
		.catch(() => {
			return dispatch({
				type: 'REQUEST_ERROR'
			});
		});
};
