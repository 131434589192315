import { submitUnsubscribeRequest } from '../Services/Unsubscribe';

const submitUnsubscribeRequestPromisse = async tipoProcesso =>
	await submitUnsubscribeRequest(tipoProcesso);

export const submitUnsubscribe = async (dispatch, tipoProcesso) => {
	submitUnsubscribeRequestPromisse(tipoProcesso)
		.then(data => {
			if (data) {
				return dispatch({
					type: 'SUBMIT_UNSUBSCRIBE',
					payload: data
				});
			}
		})
		.catch(() =>
			dispatch({
				type: 'REQUEST_ERROR'
			})
		);
};

export default null;
