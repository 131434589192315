export const USER_KEY = process.env.REACT_APP_IB_CAPITAL_USER_KEY;

const setJwtToken = dataLocalStorage => {
	const newToken = new URLSearchParams(window.location.search).get('token');

	if (!newToken) {
		return;
	}

	if (!dataLocalStorage || !dataLocalStorage.jwtToken || dataLocalStorage.jwtToken !== newToken) {
		!dataLocalStorage
			? (dataLocalStorage = { jwtToken: newToken })
			: (dataLocalStorage.jwtToken = newToken);
		window.localStorage.clear();
		window.localStorage.setItem(USER_KEY, JSON.stringify(dataLocalStorage));
	}

	formatURL();
};

export const User = {
	get: () => {
		const dataLocalStorage = JSON.parse(window.localStorage.getItem(USER_KEY));
		setJwtToken(dataLocalStorage);
		return JSON.parse(window.localStorage.getItem(USER_KEY));
	},
	save: ({ razaoSocial, idProcesso, jwtToken, nome, email, tipoProcesso, grade }) => {
		const dataString = {
			razaoSocial,
			idProcesso,
			jwtToken,
			nome,
			email,
			tipoProcesso,
			grade
		};
		window.localStorage.removeItem(USER_KEY);
		window.localStorage.setItem(USER_KEY, JSON.stringify(dataString));
	},
	remove: () => {
		window.localStorage.removeItem(USER_KEY);
	}
};

function formatURL() {
	let url = document.location.href;
	const tokenPosition = url.indexOf('?');

	if (tokenPosition !== -1) {
		url = url.substring(0, tokenPosition);
	}

	window.history.pushState({}, document.title, url);
}

export default null;
