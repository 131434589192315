import { submitSearchCNPJRequest } from '../Services/SearchCNPJ';

const submitSearchCNPJRequestPromisse = async cnpj => await submitSearchCNPJRequest(cnpj);

export const submitSearchCNPJ = async (dispatch, cnpj) => {
  submitSearchCNPJRequestPromisse(cnpj).then((data) => {
    if (data) {
      return dispatch({
        type: 'REQUEST_CNPJ_DATA',
        payload: data,
      });
    }
  }).catch(() => dispatch({
    type: 'REQUEST_ERROR',
  }));
};

export default null;
