import React from 'react';
import CssTextField from '../../Component/Input/CssTextField';
import Buttonn from '../../Component/Button/Button';
import { Store } from '../../Infrastructure/Store/Store';
import { ReactComponent as Spinner } from '../../Common/Assets/images/white-Spinner.svg';

import './SubmitNps.scss';
import { Fragment } from 'react';

const renderTituloMensagem = state => (
	<div className='mensagem-wrapper'>
		<div className='text-mensagem' data-cy='titulo'>
			Muito obrigado {state.contato ? state.contato : state.razaoSocial} por sua avaliação!{' '}
		</div>
	</div>
);

const renderMensagem = () => (
	<div className='nps-texto' data-cy='subtitulo'>
		Você nos incentiva a crescer cada dia mais para te proporcionar uma melhor experiência conosco!
	</div>
);

function SubmitNps({ loading, setLoading, setJustificativa, envioJustificativa, enviado }) {
	const { state } = React.useContext(Store);

	return (
		<div className='unsubscribe-painel-wrapper'>
			{!loading &&
			enviado && (
				<Fragment>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<p style={{ fontSize: 52, textAlign: 'center' }}>
							Envio da justificativa <br />realizado com sucesso!
						</p>
					</div>
				</Fragment>
			)}

			{!enviado && (
				<Fragment>
					{renderTituloMensagem(state)}
					<div className='unsubscribe-wrapper'>{renderMensagem()}</div>
					<CssTextField
						autoFocus
						fullWidth
						label='Deixe aqui sua justificativa:'
						multiline
						rows='6'
						variant='outlined'
						id='justificativa'
						name='justificativa'
						data-cy='justificativa'
						onChange={e => setJustificativa(e.target.value)}
					/>
					<div className='button nps__button'>
						<Buttonn
							onClick={() => {
								setLoading(true);
								envioJustificativa();
							}}
							data-cy='botao'
						>
							<span>
								{loading ? (
									<div className='home-page__white-spinner'>
										<Spinner className='submit-loading-button-icon' /> Enviar{' '}
									</div>
								) : (
									<div className='align-spinner'> Enviar </div>
								)}
							</span>
						</Buttonn>
					</div>
				</Fragment>
			)}
		</div>
	);
}
export default SubmitNps;
