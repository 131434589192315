import axios from 'axios';
import { User } from '../../Common/Helpers/AuthHelper';
import { BFF_URL } from '../../Common/Helpers/ApiHelper';

export const getStepsRepo = tipoProcesso => {
	const token = User.get().jwtToken;
	const url = `${BFF_URL}/timelineSteps?tipoProcesso=${tipoProcesso}`;
	const header = {
		headers: { Authorization: 'Bearer ' + token }
	};

	return new Promise((resolve, reject) => {
		axios.get(url, header).then(result => resolve(result)).catch(error => {
			reject(error);
		});
	});
};
