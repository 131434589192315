import React, { useState, useEffect } from 'react';
import Header from 'Component/Header/index';
import { Container } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import Email from '../../Common/Assets/images/email.svg';
import CssTextField from '../../Component/Input/CssTextField';
import { ReactComponent as Spinner } from '../../Common/Assets/images/white-Spinner.svg';
import Buttonn from '../../Component/Button/Button';
import { validateEmail } from '../../Common/Helpers/Validations';
import { Store } from '../../Infrastructure/Store/Store';
import Alert from '../../Component/Alert';
import TypeWriter from '../../Component/TypeWriter';
import { User } from '../../Common/Helpers/AuthHelper';

import { submitCheckEmail } from '../../Infrastructure/Actions/CheckEmail';

import './Home.scss';

function Home() {
	const { state, dispatch } = React.useContext(Store);
	let tipoProcessoQuerString = new URLSearchParams(window.location.search).get('tipoProcesso');

	let tipoProcesso = tipoProcessoQuerString ? tipoProcessoQuerString : 'abertura';

	const [
		loading,
		setLoading
	] = useState(false);
	const [
		email,
		setEmail
	] = useState('');
	const [
		error,
		setError
	] = useState(false);

	useEffect(
		() => {
			setLoading(false);
		},
		[
			state
		]
	);

	const updateData = e => {
		setEmail(e.target.value);
	};

	const validateEmailInput = event => {
		if (!event || !event.target || !event.target.value) {
			setError(false);
			return;
		}

		setError(!validateEmail(event.target.value));
	};

	const user = User.get();
	if (user && user.jwtToken) {
		User.save({ ...user, tipoProcesso });
		return <Redirect to={{ pathname: '/documentos' }} />;
	}

	if (state.existsEmail === false) {
		return <Redirect to={{ pathname: '/cadastro' }} />;
	}
	if (state.existsEmail === true) {
		return <Redirect to={{ pathname: '/codigo' }} />;
	}

	return (
		<div className='page_background'>
			<Header />
			<Alert />
			<Container className='content-page home-page'>
				<div className='content-page__inner'>
					<TypeWriter
						phrase='Seja bem vindo à abertura de conta digital!'
						timer='75'
						css='home-page__title'
						data-cy='title'
					/>
					<div className='fade-in_4-8s'>
						<div className='home-page__image'>
							<img
								src={Email}
								className='home-page__image_responsive'
								data-cy='image'
								alt='Image de um envelope'
							/>
						</div>
						<TypeWriter
							className='fade-in_4-8s'
							phrase='Informe seu e-mail'
							starTimer={4100}
							timer={85}
							css='home-page__subTitle'
							data-cy='subTitle'
						/>
					</div>
					<div className='textField fade-in_7s'>
						<CssTextField
							autoFocus
							label='E-mail'
							type='email'
							name='email'
							id='email'
							variant='outlined'
							onChange={updateData}
							onBlur={validateEmailInput}
							fullWidth
							error={error}
							data-cy='email-input'
						/>
						<div className='button home-page__button'>
							<Buttonn
								disabled={!email || error}
								onClick={() => {
									setLoading(true);
									submitCheckEmail(dispatch, email);
								}}
								data-cy='btn'
							>
								<span>
									{loading ? (
										<div className='home-page__white-spinner'>
											<Spinner className='submit-loading-button-icon' /> Prosseguir{' '}
										</div>
									) : (
										<div className='align-spinner'> Prosseguir </div>
									)}
								</span>
							</Buttonn>
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
}

export default Home;
