import axios from 'axios';
import { User } from '../../Common/Helpers/AuthHelper';
import { BFF_URL } from '../../Common/Helpers/ApiHelper';

export const submitUnsubscribe = tipoProcesso => {
	const { jwtToken, email } = User.get();
	const url = `${BFF_URL}/unsubscribe?tipoProcesso=${tipoProcesso}`;
	const header = {
		headers: { Authorization: `Bearer ${jwtToken}` }
	};

	return new Promise((resolve, reject) => {
		axios.post(url, { email }, header).then(result => resolve(result)).catch(error => {
			reject(error);
		});
	});
};

export default null;
