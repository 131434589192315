import React, { useContext, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Store } from '../../Infrastructure/Store/Store';

export default function AlertRender() {
  const { state } = useContext(Store);
  const customToastId = '799022';

  useEffect(() => {
    if ((state.requestError ||
      state.requestDocumentsError ||
      state.requestProcessoError)
      && !toast.isActive(customToastId)) {

      toast.error(state.messageError, {
        toastId: customToastId,
        position: "top-right",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
    }
  }, [state])


  
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable
        bodyClassName="body"
      />
    </div>
  );

}
