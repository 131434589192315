import axios from 'axios';
import { BFF_URL } from '../../Common/Helpers/ApiHelper';

export const submitForm = (obj) => {
  const url = `${BFF_URL}/selfRegistration`;

  return new Promise((resolve, reject) => {
    axios.post(url, obj).then((result) => {
      setTimeout(() => {
        resolve(result);
      }, 3000);
    }).catch((error) => {
      reject(error);
    });
  });
};

export const getCommercials = () => {
  const url = `${BFF_URL}/commercials`;

  return new Promise((resolve, reject) => {
    axios.get(url).then((result) => {
      resolve(result);
    }).catch((error) => {
      reject(error);
    });
  });
};
