import { submitEvaluationRequest } from '../Services/Evaluation';

const submitEvaluationRequestPromisse = async (grade, justificativa) =>
	await submitEvaluationRequest(grade, justificativa);

export const submitEvaluation = async (dispatch, grade, justificativa) => {
	submitEvaluationRequestPromisse(grade, justificativa)
		.then(data => {
			return dispatch({
				type: 'SET_AVALIATION_GRADE',
				payload: data.grade
			});
		})
		.catch(() =>
			dispatch({
				type: 'REQUEST_ERROR'
			})
		);
};

export default null;
