export const errorHandling = response => {
    const MESSAGE_ALERT_ERROR = 'Tente novamente ou entre em contato com o suporte da IB Capital.';
    
    if(response === undefined)
        return MESSAGE_ALERT_ERROR;

    let formatMessage = '';
    let messages = response.data._messages;

    messages.forEach((message, index) => {
        formatMessage = index ===  messages.length - 1 ? message.text : message.text + ' - '
    })

    return formatMessage;
}