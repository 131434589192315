import React from 'react';
import Header from 'Component/Header/index';
import Timeline from '../../Component/Timeline';
import { User } from '../../Common/Helpers/AuthHelper';
import { Store } from '../../Infrastructure/Store/Store';
import { fetchDataAction } from '../../Infrastructure/Actions/Steps';
import { Container } from '@material-ui/core';

import './Finished.scss';

function Finished() {
	const { state, dispatch } = React.useContext(Store);
	let tipoProcesso = User.get().tipoProcesso;

	React.useEffect(
		() => {
			fetchDataAction(dispatch, tipoProcesso);
		},
		[
			dispatch
		]
	);

	function getNameNextCurrentStep() {
		if (state.steps && state.steps.length === 0) return;

		const index = state.steps.findIndex(step => step.currentStep);

		if (index === -1) {
			const acao = tipoProcesso === 'abertura' ? 'abertura de contas' : 'renovação de cadastro';
			return <span>Parabéns sua {acao} na IB Capital foi finalizada com sucesso.</span>;
		}
		return state.steps && index + 1 < state.steps.length ? (
			<span>
				Nosso time está analisando todas as informações enviadas e o próximo passo será:
				<span className='text-step-message'> {state.steps[index + 1].name}.</span>{' '}
			</span>
		) : (
			'.'
		);
	}

	return (
		<div>
			<Header />
			<Container>
				<div className='flexbox'>
					<div className='text-identification-corporate-name'>
						<div>{User.get().razaoSocial}</div>
					</div>
					<div className='step'>
						<Timeline steps={state.steps} />
					</div>
					<div className='end-message'>
						{getNameNextCurrentStep()}
						<br />
						Não se preocupe, você será avisado por e-mail a cada atualização.
						<p>
							Para saber mais sobre a IB Capital, acesse nosso{' '}
							<a href='http://www.ibcapital.com.br/' className='active'>
								site.
							</a>
						</p>
					</div>
				</div>
			</Container>
		</div>
	);
}

export default Finished;
