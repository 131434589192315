import { submitCheckEmail } from '../Repositories/CheckEmail';
import { errorHandling } from '../../Common/Helpers/ErrorHandling';
import { User } from '../../Common/Helpers/AuthHelper';

export const checkEmail = async (email) => {
    try {
        const response = await submitCheckEmail(email);
        storeTokenInLocalStorage(response.data);
        return response.data;
    } catch (error) {
        throw errorHandling(error.response);
    }
};

export default null;

const storeTokenInLocalStorage = (data) => {
    const email = data.email;
    
    User.save({
      email
    });
};