import React, { useEffect } from 'react';
import { Switch, BrowserRouter, Route } from 'react-router-dom';
import { loadReCaptcha } from 'react-recaptcha-google';

import { Store } from '../Infrastructure/Store/Store';
// import { User } from '../../Common/Helpers/AuthHelper';

import Home from './Home';
import Documents from './Documents';
import Unsubscribe from './Unsubscribe';
import Finished from './Finished';
import Register from './Register';
import Code from './Code';
import Nps from './Nps';
import RenewalConfirmation from './RenewalConfirmation/index';

function App() {
  const { state } = React.useContext(Store);

  useEffect(() => {
    loadReCaptcha();
  }, []);

  if (!state.noToken) {
    return (
      <>
        <BrowserRouter>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/codigo" exact component={Code} />
            <Route path="/desinscrever" component={Unsubscribe} />
            <Route path="/unsubscribe" component={Unsubscribe} />
            <Route path="/cadastro" component={Register} />
            <Route path="/acompanhamento" component={Finished} />
            <Route path="/documentos" component={Documents} />
            <Route path="/avaliacao" component={Nps} />
            <Route path="/confirmacaoRenovacao" component={RenewalConfirmation} />
            <Route path="*" component={Home} />
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}

export default App;
