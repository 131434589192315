import axios from 'axios';
import { BFF_URL } from '../../Common/Helpers/ApiHelper';

export const submitSearchCNPJ = (cnpj) => {
  const url = `${BFF_URL}/searchCNPJ?cnpj=${cnpj}`;

  return new Promise((resolve, reject) => {
    axios.get(url).then((result) => {
      resolve(result);
    }).catch((error) => {
      reject(error);
    });
  });
};
