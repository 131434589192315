import { checkEmail } from '../Services/CheckEmail';

const checkEmailPromisse = async (email) => {
  return await checkEmail(email);
}

export const submitCheckEmail = async (dispatch, email) => {
    checkEmailPromisse(email).then(data => {
    if (data) {
      return dispatch({
        type: 'SUBMIT_CHECK_EMAIL',
        payload: {existsEmail: data.emailEncontrado, email: email}
      });
    }
  }).catch((error) => {
    return dispatch({
      type: 'REQUEST_ERROR',
      payload: error
    });
  });
};

export default null;
