import React from 'react';
import { Container } from '@material-ui/core';

import './Header.scss';

function Header() {
  const renderLogo = () => <div className="header-logo-wrapper" />;

  return (
    <div className="header-wrapper">
      <Container className="header-inner-wrapper">
        {renderLogo()}
      </Container>
    </div>
  );
}

export default Header;
