import axios from 'axios';
import { User } from '../../Common/Helpers/AuthHelper';
import { BFF_URL } from '../../Common/Helpers/ApiHelper';

export const submitEvaluation = (grade, justificativa) => {
	const { jwtToken } = User.get();
	const url = `${BFF_URL}/evaluation`;
	const header = {
		headers: { Authorization: `Bearer ${jwtToken}` }
	};

	return new Promise((resolve, reject) => {
		axios
			.post(url, { grade, justificativa }, header)
			.then(result => resolve(result))
			.catch(error => {
				reject(error);
			});
	});
};

export default null;
