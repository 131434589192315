import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const CssTextField = withStyles({
    root: {
      'notchedOutline': {
        borderColor: 'green',
     },
      '& .MuiInputBase-input': {
        cursor: "pointer"
      },
      '& label.Mui-focused': {
        color: '#CD950C'
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'grey',
          borderWidth: "2px"
        },
        '&:hover fieldset': {
          borderColor: 'grey',
          borderWidth: "2px"
        },
        '&.Mui-focused fieldset': {
          borderColor: '#CD950C',
          borderWidth: "3px"
        }
      }
    },
  })(TextField);

export default CssTextField;