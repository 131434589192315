import React, { createContext, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';

const MESSAGE_ALERT_ERROR = 'Tente novamente ou entre em contato com o suporte da IB Capital.';

const initialState = {
	email: '',
	contato: '',
	razaoSocial: '',
	idProcesso: '',
	listaDocumentos: [],
	loadingDocumentos: true,
	loadingCliente: true,
	painelEnviado: false,
	sentUnsubscribe: false,
	noToken: false,
	loadingSteps: true,
	loadingSubmit: false,
	steps: [],
	requestError: false,
	messageError: '',
	showMessageError: true,
	successfulRegistration: false,
	existsEmail: '',
	validCode: '',
	agentesComerciais: [],
	cnpj: {},
	grade: 0
};

export const Store = createContext(initialState);

export const useAppContext = () => useContext(Store);

export function reducer(state, action) {
	switch (action.type) {
		case 'FETCH_DOCUMENTOS':
			return {
				...state,
				listaDocumentos: action.payload.documentos,
				painelEnviado: action.payload.painelEnviado,
				etapaDeProcesso: action.payload.etapa,
				loadingDocumentos: false,
				requestDocumentsError: false
			};
		case 'FETCH_PROCESSO':
			return {
				...state,
				razaoSocial: action.payload.razaoSocial,
				contato: action.payload.nome,
				idProcesso: action.payload.idProcesso,
				loadingCliente: false,
				requestProcessoError: false
			};
		case 'FETCH_PROCESSO_ERROR':
			return {
				...state,
				noToken: true
			};
		case 'SET_FILE':
			return {
				...state,
				listaDocumentos: action.payload,
				requestError: false
			};
		case 'SET_AVALIATION_GRADE':
			return {
				...state,
				grade: action.payload
			};
		case 'RENEWAL_CONFIRMATION':
			return {
				...state,
				valid: action.payload.valid
			};
		case 'SUBMIT_FORM':
			return {
				...state,
				painelEnviado: action.payload,
				requestError: false
			};
		case 'SUBMIT_UNSUBSCRIBE':
			return {
				...state,
				loadingSubmit: false,
				sentUnsubscribe: action.payload,
				requestError: false
			};

		case 'FETCH_STEPS':
			return {
				...state,
				steps: action.payload.steps,
				loadingSteps: false,
				requestError: false
			};
		case 'SUBMIT_REGISTER_LEAD':
			return {
				...state,
				requestError: false,
				successfulRegistration: true
			};
		case 'SUBMIT_CHECK_EMAIL':
			return {
				...state,
				requestDocumentsError: false,
				existsEmail: action.payload.existsEmail,
				email: action.payload.email
			};
		case 'SUBMIT_VALIDATE_CODE':
			return {
				...state,
				validCode: action.payload
			};
		case 'REQUEST_CNPJ_DATA':
			return {
				...state,
				razaoSocial: action.payload.razaoSocial,
				nomeFantasia: action.payload.nomeFantasia,
				telefone: action.payload.telefone,
				getCnpjData: !state.getCnpjData
			};
		case 'REQUEST_DOCUMENTS_ERROR':
			return {
				...state,
				requestDocumentsError: true,
				messageError: action.payload ? action.payload : MESSAGE_ALERT_ERROR
			};
		case 'REQUEST_PROCESSO_ERROR':
			return {
				...state,
				requestProcessoError: true,
				messageError: action.payload ? action.payload : MESSAGE_ALERT_ERROR
			};
		case 'REQUEST_ERROR':
			return {
				...state,
				requestError: true,
				messageError: action.payload ? action.payload : MESSAGE_ALERT_ERROR
			};
		case 'REQUEST_CODE_ERROR':
			return {
				...state,
				validCode: false,
				messageError: action.payload ? action.payload : MESSAGE_ALERT_ERROR
			};
		case 'CLEAN_ERRORS':
			return {
				...state,
				requestError: false,
				requestDocumentsError: false,
				requestContactsError: false
			};
		case 'CLEAN_CODE_ERROR':
			return {
				...state,
				validCode: ''
			};
		case 'CLEAN_EMAIL':
			return {
				...state,
				existsEmail: ''
			};
		case 'GET_AGENTES_COMERCIAIS':
			return {
				...state,
				agentesComerciais: action.payload
			};
		default:
			return { ...state };
	}
}

export function StoreProvider(props) {
	const [
		state,
		dispatch
	] = useReducer(reducer, initialState);
	const value = { state, dispatch };
	const { children } = props;
	return <Store.Provider value={value}>{children}</Store.Provider>;
}

StoreProvider.propTypes = {
	children: PropTypes.node.isRequired
};
