import React from 'react';
import Cancel from "../../Common/Assets/images/ic_close.svg";

import './InputError.scss';

function InputError(props) {
    if (props.error) {
        return <> 
            <div className="error-input_out" >
                <img 
                    className="ic-close_size" 
                    src={Cancel}
                /> 
                <div 
                    className="error-input__label">
                    <strong>{props.value ? `${props.fieldName} inválido` : `${props.fieldName}  não pode ficar vazio`}</strong>
                </div>
            </div>
        </> 
    } else {
        return <></>
    }
}

export default InputError;