import { submit, getAgentsCommercials } from '../Services/Register';

const submitPromisse = async obj => await submit(obj);

const getAgentsCommercialsPromisse = async () => await getAgentsCommercials();

export const submitRegisterLead = async (dispatch, obj) => {
  submitPromisse(obj).then((data) => {
    if (data) {
      return dispatch({
        type: 'SUBMIT_REGISTER_LEAD',
      });
    }
  }).catch(error => dispatch({
    type: 'REQUEST_ERROR',
    payload: error,
  }));
};

export const getEmpresa = async () => {

};

export const getAgentesComerciais = async (dispatch) => {
  getAgentsCommercialsPromisse().then((data) => {
    if (data) {
      return dispatch({
        type: 'GET_AGENTES_COMERCIAIS',
        payload: data.agentesComerciais,
      });
    }
  }).catch(error => dispatch({
    type: 'REQUEST_ERROR',
    payload: error,
  }));
};

export default null;
