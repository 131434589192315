import Button from '@material-ui/core/Button';
import {withStyles,} from '@material-ui/core/styles';

const lightBrown = '#CD950C';
const DarkGoldenrod = '#B8860B';

const Buttonn = withStyles({
    root: {
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: 18,
      padding: '12px 30px',
      border: '1px solid',
      'min-width': '185px',
      lineHeight: 1.5,
      backgroundColor: lightBrown,
      borderColor: lightBrown,
      opacity: 1,
      '&:first-child': {
        color:'#FFFFFF',
        fontSize: "20px",
        cursor:"pointer",
        fontWeight: "bold"
      },
      '&:hover': {
        backgroundColor: DarkGoldenrod,
        borderColor: DarkGoldenrod,
        boxShadow: DarkGoldenrod,
      },
      '&:focus': {
        backgroundColor: lightBrown,
        borderColor: lightBrown,
        boxShadow: lightBrown,
      },
      '&:disabled': {
        opacity: 0.7,
      }
    },
  })(Button);


export default Buttonn;