import { getSteps } from '../Services/Steps';

const getStepsPromisse = async tipoProcesso => {
	return await getSteps(tipoProcesso);
};

export const fetchDataAction = async (dispatch, tipoProcesso) => {
	getStepsPromisse(tipoProcesso)
		.then(data => {
			if (data) {
				return dispatch({
					type: 'FETCH_STEPS',
					payload: data
				});
			}
		})
		.catch(() => {
			return dispatch({
				type: 'REQUEST_ERROR'
			});
		});
};
